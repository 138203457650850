<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row>
          <Drawer />
          <v-col
            cols="12"
            md="9"
            class="ml-auto pa-0"
          >
            <Header>
              <template v-slot:title>
                <span class="mr-3 text-sm-h4 white--text font-weight-bold">{{ $t('config-devis-commande') }} ({{ commandes.length }})</span>
              </template>
            </Header>
            <v-row class="ma-1 ml-0">
              <v-col
                v-if="commandes && commandes.length > 0"
                cols="12"
                md="8"
              >
                <div
                  class="d-flex flex-column flex-md-row align-start"
                >
                  <v-btn
                    class="mb-4 mr-4 px-2"
                    :href="piscinelleUrl+'tarifs?email=' + user.email"
                    small
                  >
                    <v-icon
                      class="vertical-baseline mr-1"
                      small
                    >
                      mdi-plus
                    </v-icon>{{ $t('nouvelle-config') }}
                  </v-btn>
                  <v-btn
                    class="mb-4 px-2"
                    :href="petiteSourceUrl+'tarifs?email=' + user.email"
                    small
                  >
                    <v-icon
                      class="vertical-baseline mr-1"
                      small
                    >
                      mdi-plus
                    </v-icon>{{ $t('nouvelle-config-ps') }}
                  </v-btn>
                </div>
                <v-card>
                  <v-list
                    class="py-0"
                    two-line
                  >
                    <template
                      v-for="(offre, index) in commandes"
                    >
                      <v-divider
                        v-if="index > 0"
                        :key="`divider-${index}`"
                      />
                      <v-list-item
                        :key="`item-${index}`"
                        class="d-block pb-2 rounded"
                        :to="{name: 'Commande', params: {id: offre.id}}"
                      >
                        <div
                          class="d-flex"
                          :class="$vuetify.breakpoint.xsOnly ? 'listing-commandes' : ''"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              <p class="mb-1">
                                {{ $t('publication', {type: offre.statut === 'facture' || offre.statut === 'commande' ? $t('commande.statut.commande') : (offre.internaute ? $t('commande.statut.config') : $t('commande.statut.devis')), date: $d(new Date(offre.date.replace(/-/g, "/")), 'short')}) }}
                              </p>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <template v-for="(tag, tagIndex) in offre.tags">
                                <v-chip
                                  :key="tagIndex"
                                  small
                                  class="mr-2 tag"
                                  :color="offre.internaute ? 'blue' : tag.statut == 'devis' ? 'orange' : tag.statut == 'facture' ? 'dark-blue' : 'green'"
                                  text-color="white"
                                  label
                                >
                                  {{ offre.internaute ? ((offre.is_petite_source ? $t('config-ps-non-contractuelle') : $t('config-non-contractuelle')) + ' #' + tag.id) : $t('commande.statut.'+ tag.statut) + ' ' + $t('commande.type.'+ tag.type) + ' ' + $t('commande.title', {id: tag.id}) }}
                                </v-chip>
                              </template>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <span>{{ $d(new Date(offre.date.replace(/-/g, "/")), 'short') }}</span> <span v-if="offre.titre">&#183; {{ offre.titre }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="align-self-start">
                            <span
                              v-if="!offre.is_petite_source"
                              class="text-body-2"
                            >
                              {{ $n(offre.cout, 'currency') }}
                            </span>
                            <span
                              v-else
                              class="text-body-2"
                            >
                              {{ $n(vpm(offre.cout,180), 'currency') }} {{ $t('par-mois') }}
                            </span>
                          </v-list-item-action>
                        </div>
                        <v-chip
                          v-if="offre.statut == 'commande' && offre.signature && offre.signature.date_validite >= today()"
                          small
                          color="blue"
                          class="chip-command-date-expiration text-center d-block rounded py-1"
                          text-color="white"
                          label
                        >
                          <span><v-icon
                            class="vertical-baseline mr-1"
                            small
                          >mdi-pencil</v-icon>{{ $t('signature-prete') }}</span>
                        </v-chip>
                        <v-chip
                          v-if="offre.statut == 'devis' && !offre.internaute"
                          small
                          :color="offre.internaute ? 'blue' : offre.expiration >= today() ? 'blue' : 'orange'"
                          class="chip-command-date-expiration text-center d-block rounded py-1"
                          text-color="white"
                          label
                        >
                          <div
                            v-if="!offre.internaute"
                          >
                            <span v-if="offre.expiration >= today()"><v-icon
                              class="vertical-baseline mr-1"
                              small
                            >mdi-calendar</v-icon>{{ $t('commande-date-expiration', {date: $d(new Date(offre.expiration.replace(/-/g, "/")), 'short')}) }}</span>
                            <span v-else><v-icon
                              class="vertical-baseline mr-1"
                              small
                            >mdi-calendar</v-icon>{{ $t('commande-expire', {date: $d(new Date(offre.expiration.replace(/-/g, "/")), 'short')}) }}</span>
                          </div>
                          <div
                            v-if="offre.signature && offre.expiration >= today()"
                          >
                            <span><v-icon
                              class="vertical-baseline mr-1"
                              small
                            >mdi-pencil</v-icon>{{ $t('signature-prete') }}</span>
                          </div>
                        </v-chip>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
              <v-col v-else-if="commandesLoaded">
                <v-alert
                  color="blue"
                  type="info"
                  text
                >
                  {{ $t('pas-de-commande-alert') }} {{ $t('rdv-piscinelle') }}<a
                    class="blue--text"
                    :href="piscinelleUrl + 'tarifs' + '?email=' + user.email"
                  > Piscinelle.com </a> {{ $t('creer-config') }}
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from '@/components/Header'
import Drawer from '@/components/Drawer'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Commandes',
  components: {
    Header,
    Drawer
  },
  data () {
    return {
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL,
      petiteSourceUrl: process.env.VUE_APP_PETITE_SOURCE_URL
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'commandesLoaded'
    ]),
    ...mapState({
      commandes: state => state.commandes.list
    })
  }
}
</script>
